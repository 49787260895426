import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    onConfirmFunction?: null | (() => void);
}

const ClosePopup = ({ open, onConfirm, onCancel, onConfirmFunction }: Props) => {

    const { t, ready } = useTranslation(); // Use 'ready' to check if translations are loaded

    if (!ready) {
        return null; // or a loading spinner/placeholder if preferred
    }

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{t('translation.closepopup.confirmClose')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('translation.closepopup.confirmationText')}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button variant="outlined" color="primary" sx={{ borderRadius: 20, marginRight: 5 }} disableElevation onClick={onCancel}>
                    {t('translation.closepopup.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: 20 }}
                    onClick={() => {
                        if (onConfirmFunction) {
                            onConfirmFunction();
                        }
                        setTimeout(() => {
                            onConfirm();
                        }, 50);
                    }}
                    autoFocus>
                    {t('translation.closepopup.closeWindow')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClosePopup;
