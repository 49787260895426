import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import { AccountInformation, BookingItem, LockerItem } from './interface';
import axios from './../../utils/axios';
import { LockerTypeV1Alpha1, PinCodeResponse, Product, Site } from '../product/interfaces';
import { PincodeListItem } from '../booking/PincodeListItem';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CountdownTimer from './CountdownTimer';
import RegularLockItem from './RegularLockItem';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import { ShowReturnContext } from '../return/ReturnContext';
import SkeletonUnlockFrame from '../skeletons/SkeletonUnlockFrame';
// import BeforeOpenLockPage from '../beforeBooking/BeforeOpenLockPage';

interface Props {
    bookingId: string;
    lockerItems: LockerItem[];
    pinCodesItems: PinCodeResponse[];
    setError: (error: boolean) => void;
    accountInformation: AccountInformation;
    bookingInfo: BookingItem;
    siteInformation: Site | null;
}

function LockInformation({ bookingId, lockerItems, setError, accountInformation, pinCodesItems, bookingInfo, siteInformation }: Props) {
    const { t } = useTranslation();
    const [isUnlockLoading, setIsUnlockLoading] = useState(false);
    const [unlocked, setUnlocked] = useState(false);
    const [unlockedIglo, setUnlockedIglo] = useState(false);
    const [unlockedManual, setUnlockedManual] = useState(false);
    const [earlyArrival, setEarlyArrival] = useState(false);
    const [lateArrival, setLateArrival] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [products, setProducts] = useState<Product[]>([]);
    const { setShowReturn } = useContext(ShowReturnContext);

    function resetData() {
        setUnlocked(false);
        setLateArrival(false);
        setEarlyArrival(false);
        setUnlockedIglo(false);
        setError(false);
    }

    const getAllProducts = (siteId) => {
        return axios.get(`product/v1/allProducts/${siteId}`);
    };

    async function unlockGear() {
        setIsUnlockLoading(true);
        let response: any;
        try {
            response = await axios.get(`booking/unlock/${bookingId}`);
            if (response.status === 200) {
                const data = response.data;
                setTimeout(() => {
                    if (data.lockerStatus === 'on_time') {
                        setUnlocked(true);
                        setLateArrival(false);
                        setEarlyArrival(false);
                    } else if (data.lockerStatus === 'late') {
                        setLateArrival(true);
                    } else if (data.lockerStatus === 'early') {
                        setEarlyArrival(true);
                    }
                    setIsUnlockLoading(false);
                    setTimeout(() => {
                        if (data.lockerStatus !== 'early') {
                            resetData();
                        }
                    }, 7000);
                }, 3000);
            } else {
                setError(true);
            }
        } catch (err: any) {
            console.error(err);
            setTimeout(() => {
                setError(true);
            }, 3000);
        }
    }

    async function verifyIglooBooking() {
        const response = await axios.get(`booking/verifyBooking/${bookingId}`);
        if (response.status === 200) {
            const data: any = await response.data;
            if (data.lockerStatus === 'on_time') {
                setLateArrival(false);
                setEarlyArrival(false);
            } else if (data.lockerStatus === 'late') {
                setLateArrival(true);
            } else if (data.lockerStatus === 'early') {
                setEarlyArrival(true);
            }
        }
    }

    const getLockerItemsInfo = (lockerItems: LockerItem[]) => {
        if (lockerItems?.some((lockerItem) => lockerItem?.type === LockerTypeV1Alpha1.MANUAL_LOCKER)) {
            setUnlockedManual(true);
            setUnlockedIglo(true);
            setLoading(false);
            setUnlocked(false);
        } else if (
            lockerItems?.some(
                (lockerItem) => lockerItem?.type === LockerTypeV1Alpha1.IGLOO_PADLOCK || lockerItem.type === LockerTypeV1Alpha1.STORAGE_LOCK
            )
        ) {
            setLoading(true);
            setUnlockedIglo(true);
            setUnlocked(false);
            verifyIglooBooking()
                .catch((err) => {
                    console.error(err);
                    setError(true);
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            if (products.length === 0) {
                getAllProducts(bookingInfo.siteId).then((response) => {
                    setProducts(response.data);
                    setUnlockedIglo(false);
                    setUnlocked(false);
                    setLoading(false);
                });
            } else {
                resetData();
            }
        }
    };

    useEffect(() => {
        getLockerItemsInfo(lockerItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerItems]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column', width: '100%' }}>
                <SkeletonUnlockFrame />
            </Box>
        );
    }

    function ErrorMessage() {
        // const futureMoment = moment().add(unlocksInMinutes, 'minutes');
        return (
            <div>
                {earlyArrival && (
                    <>
                        <h2>{t('translation.unlock.earlyMessage')}</h2>
                        <p>
                            {t('translation.unlock.earlyMessage2', {
                                0: moment(bookingInfo.startDate).format(
                                    siteInformation?.account.timeSettingAmPm ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm'
                                )
                            })}
                        </p>
                        <Button
                            variant="contained"
                            sx={{ mb: 2, borderRadius: '80px', height: 40 }}
                            disabled={loading}
                            onClick={() => {
                                getLockerItemsInfo(lockerItems);
                            }}>
                            {t('translation.unlock.earlyMessageReload')}
                        </Button>
                    </>
                )}
                {/* This is mock of BeforeOpenLockPage questions */}
                {
                    // lateArrival && <BeforeOpenLockPage />
                }
                {lateArrival && <h2>{t('translation.unlock.notValid')}</h2>}
                <Box
                    dangerouslySetInnerHTML={{
                        __html: t('translation.unlock.questionMessage', {
                            0: siteInformation?.email ?? '',
                            1: siteInformation?.phone ?? ''
                        })
                    }}
                    sx={{
                        'a[href^="mailto:"]': {
                            color: '#004E31'
                        },
                        'a[href^="tel:"]': {
                            color: '#004E31'
                        },
                        marginBottom: '1rem'
                    }}></Box>
            </div>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
            {!earlyArrival && !lateArrival && siteInformation?.unlockDisplaySettings?.showHeading !== false && (
                <div>
                    <h2>
                        {siteInformation?.unlockDisplaySettings?.unlockHeadings
                            ? siteInformation.unlockDisplaySettings.unlockHeadings[getCurrentLanguageFromLocalStorage()]
                            : t('translation.unlock.unlockWelcome')}
                    </h2>
                </div>
            )}
            {earlyArrival || lateArrival ? (
                <ErrorMessage />
            ) : (
                <>
                    {unlockedIglo ? (
                        // Unlocked Igloo related messages
                        <>
                            {pinCodesItems?.map((item, i) => (
                                <React.Fragment key={i}>
                                    <PincodeListItem pinData={item} />
                                </React.Fragment>
                            ))}
                            {siteInformation?.unlockDisplaySettings?.showMessage !== false && (
                                <p style={{ marginBottom: '24px' }}>
                                    {siteInformation?.unlockDisplaySettings?.unlockMessages
                                        ? siteInformation.unlockDisplaySettings.unlockMessages[getCurrentLanguageFromLocalStorage()]
                                        : t('translation.unlock.pickupInfoMessage')}
                                </p>
                            )}
                        </>
                    ) : (
                        // Non-Igloo related messages
                        <>
                            {lockerItems.map((lockerItem, i) => {
                                const product = products.find((product) => product.id === lockerItem.productId);
                                return (
                                    <RegularLockItem
                                        lockNumber={lockerItem.lockerNumber}
                                        productName={translate(product?.name, getCurrentLanguageFromLocalStorage())}
                                        pinCode={lockerItem.pinCode}
                                    />
                                );
                            })}
                            {unlocked && <p style={{ color: '#004e31', fontWeight: 'bold' }}>{t('translation.unlock.productUnlocked')}</p>}
                            <Button
                                disabled={isUnlockLoading}
                                variant="contained"
                                onClick={unlockGear}
                                sx={{
                                    borderRadius: '80px',
                                    height: 40,
                                    marginTop: '12px'
                                }}>
                                {isUnlockLoading ? (
                                    <CircularProgress
                                        size={30}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-16px',
                                            marginLeft: '-16px',
                                            color: 'white'
                                        }}
                                    />
                                ) : (
                                    t('translation.unlock.unlockProduct')
                                )}
                            </Button>
                            <p>{t('translation.unlock.unlockStandard')}</p>
                        </>
                    )}
                    {siteInformation?.unlockDisplaySettings?.showTimer !== false && (
                        <CountdownTimer targetDate={bookingInfo.endDate} timezone={siteInformation?.timezone} />
                    )}
                    {siteInformation?.unlockDisplaySettings?.showConfirmButton !== false && (
                        <Button
                            variant="outlined"
                            sx={{ borderRadius: '80px', height: 40, marginTop: '16px', color: 'black', borderColor: 'black', borderWidth: 2 }}
                            onClick={() => {
                                setShowReturn(true);
                            }}>
                            {t('translation.unlock.stopRent')}
                        </Button>
                    )}
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: t('translation.unlock.questionMessage', {
                                0: siteInformation?.email,
                                1: siteInformation?.phone
                            })
                        }}
                        sx={{
                            'a[href^="mailto:"]': {
                                color: '#004E31'
                            },
                            'a[href^="tel:"]': {
                                color: '#004E31'
                            },
                            marginTop: '2rem',
                            marginBottom: '1rem'
                        }}></Box>
                </>
            )}
        </Box>
    );
}

export default LockInformation;
