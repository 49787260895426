import React from 'react';
import { Box, Stack, Grid, FormControl, Select, MenuItem, IconButton, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import { BookableEventV1, EventV1 } from '../product/interfaces'; // Update import path
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util'; // Update import path

interface Props {
    selectedEvents: BookableEventV1[];
    setSelectedEvents?: (events: BookableEventV1[]) => void;
    currencyISO: string;
    disableEdit?: boolean;
    availableEvents: EventV1[];
    timeSettingAmPm: boolean;
}

const EventSummary: React.FC<Props> = ({ selectedEvents, setSelectedEvents, currencyISO, disableEdit = false, availableEvents, timeSettingAmPm }) => {
    const updateQuantity = (quantity: number, event: BookableEventV1) => {
        const selectedEventsReturn = [...selectedEvents];
        const objIndex = selectedEventsReturn.findIndex(
            (selectedEvent) => selectedEvent.id === event.id && selectedEvent.priceGroup === event.priceGroup
        );
        if (objIndex >= 0) {
            selectedEventsReturn[objIndex].quantity = quantity;
        }

        if (setSelectedEvents) {
            setSelectedEvents(selectedEventsReturn);
        }
    };

    const removeEvent = (event: BookableEventV1) => {
        const selectedEventsReturn = [...selectedEvents];
        const objIndex = selectedEventsReturn.findIndex(
            (selectedEvent) => selectedEvent.id === event.id && selectedEvent.priceGroup === event.priceGroup
        );

        if (objIndex >= 0) {
            selectedEventsReturn.splice(objIndex, 1);
        }

        if (setSelectedEvents) {
            setSelectedEvents(selectedEventsReturn);
        }
    };

    const getEventFromOccasion = (selectedEventOccasion: BookableEventV1) => {
        const event = availableEvents.find((event) => event.id === selectedEventOccasion.eventId);
        return event;
    };

    const getAvailability = (selectedEvent: BookableEventV1) => {
        if (selectedEvent.priceGroup) {
            let selectedQuantity = 0;

            selectedEvents.forEach((event) => {
                if (event.id === selectedEvent.id && event.priceGroup !== selectedEvent.priceGroup) {
                    selectedQuantity += event.quantity;
                }
            });
            return selectedEvent.attendees - selectedQuantity;
        } else {
            return selectedEvent.attendees ?? 0;
        }
    };

    const formatTime = (time, use12HourFormat) => {
        const dateFormat = 'YYYY-MM-DD';
        const timeFormat12hr = 'hh:mm A';
        const timeFormat24hr = 'HH:mm';
        // Combine date and time formats based on whether the 12-hour or 24-hour format is used
        const dateTimeFormat = `${dateFormat} ${use12HourFormat ? timeFormat12hr : timeFormat24hr}`;
    
        return moment(time).format(dateTimeFormat);
    };

    return (
        <Stack spacing={1} sx={{ px: 0, py: 1 }}>
            <Grid container spacing={0}>
                {selectedEvents.map((selectedEvent) => {
                    const event = getEventFromOccasion(selectedEvent);
                    let translatedPriceGroup = '';
                    let cost = 0;

                    if (typeof selectedEvent.priceGroup === 'string') {
                        translatedPriceGroup = translate(event?.priceGroup?.[selectedEvent.priceGroup], getCurrentLanguageFromLocalStorage());
                        cost = event?.priceGroup?.[selectedEvent.priceGroup].cost ?? 0;
                    } else if (typeof selectedEvent.priceGroup === 'object') {
                        translatedPriceGroup = translate(selectedEvent.priceGroup, getCurrentLanguageFromLocalStorage());
                        cost = selectedEvent.priceGroup.cost;
                    }

                    if (!event) {
                        return null;
                    }

                    return (
                        <Grid
                            container
                            item
                            spacing={0}
                            sx={{
                                alignItems: 'center',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                                pb: 1,
                                pt: 1,
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap'
                            }}
                            key={
                                selectedEvent.priceGroup && typeof selectedEvent.priceGroup === 'string' ? selectedEvent.priceGroup : selectedEvent.id
                            }>
                            {!disableEdit && (
                                <Grid item xs={1} sm={1} md={1}>
                                    <IconButton onClick={() => removeEvent(selectedEvent)} size="small">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item xs={2} sm={2} md={2} sx={{ textAlign: 'center' }}>
                                <Box
                                    sx={{
                                        alignSelf: 'center',
                                        height: '60px',
                                        width: '60px',
                                        textAlign: 'center',
                                        display: 'flex'
                                    }}>
                                    <img
                                        alt="event"
                                        src={event.productImageUrl}
                                        style={{
                                            maxHeight: 60,
                                            maxWidth: 60,
                                            margin: 'auto',
                                            padding: '4px'
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={5}
                                sm={5}
                                md={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    minWidth: 60, //'160px', // Prevents the content from becoming too narrow
                                    padding: '16px'
                                }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                                    {translate(event.name ?? '', getCurrentLanguageFromLocalStorage())}
                                </Typography>
                                {selectedEvent.priceGroup && event.priceGroup && (
                                    <Typography variant="body2" sx={{ fontSize: '12px', mb: 0.5 }} paddingLeft={1}>
                                        {translatedPriceGroup}
                                    </Typography>
                                )}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        mb: 0.5,
                                        wordWrap: 'break-word', // Allow long words to be broken and wrap onto the next line
                                        maxWidth: '100%' // Ensure the text is contained within the width of the parent
                                    }}>
                                    {translate(event.shortDescription, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px', mb: 0.5 }}>
                                    {formatTime(selectedEvent.startDate, timeSettingAmPm)} - {formatTime(selectedEvent.endDate, timeSettingAmPm)}
                                </Typography>
                                {selectedEvent.info && disableEdit && (
                                    <Typography variant="body2" sx={{ fontSize: '12px', mb: 0.5 }}>
                                        {selectedEvent.info}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={disableEdit ? 1 : 2}>
                                {disableEdit ? (
                                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                        {selectedEvent.bookedAttendees}
                                    </Typography>
                                ) : (
                                    <FormControl sx={{ minWidth: 50 }}>
                                        <Select
                                            value={selectedEvent.quantity}
                                            onChange={(event) => {
                                                updateQuantity(event.target.value as number, selectedEvent);
                                            }}
                                            sx={{
                                                borderRadius: 20,
                                                maxHeight: 40,
                                                border: 'solid 1px #004E31'
                                            }}
                                            MenuProps={{ sx: { zIndex: 2147483647 } }}
                                            size="small">
                                            {[...Array(getAvailability(selectedEvent)).keys()].map((key) => (
                                                <MenuItem key={key + 1} value={key + 1}>
                                                    {key + 1}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">
                                    {((selectedEvent.priceGroup && event.priceGroup && cost) || event.cost) *
                                        (selectedEvent.quantity ?? selectedEvent.bookedAttendees)}
                                    {currencyISO}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Stack>
    );
};

export default EventSummary;
