import { Box, Skeleton } from '@mui/material';

export default function SkeletonCardFooter() {
    return (
        <Box
            position={'sticky'}
            sx={{
                top: 'auto',
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: '8px',
                boxShadow: 'none',
                backgroundColor: '#ffffff',
                px: 2,
                height: '92px',
                zIndex: 9999
            }}>
            <Skeleton variant="rectangular" width={'100%'} height={40} sx={{ borderRadius: '20px' }} />
            <Skeleton variant="rectangular" width={'30%'} height={20} sx={{ borderRadius: '20px', mt: 1.5 }} />
        </Box>
    );
}
