export const CurrencySymbol = {
    sek: ' SEK',
    eur: ' EUR',
    usd: ' USD',
    aed: ' AED',
    afn: ' AFN',
    all: ' ALL',
    amd: ' AMD',
    ang: ' ANG',
    aoa: ' AOA',
    ars: ' ARS',
    aud: ' AUD',
    awg: ' AWG',
    azn: ' AZN',
    bam: ' BAM',
    bbd: ' BBD',
    bdt: ' BDT',
    bgn: ' BGN',
    bif: ' BIF',
    bmd: ' BMD',
    bnd: ' BND',
    bob: ' BOB',
    brl: ' BRL',
    bsd: ' BSD',
    bwp: ' BWP',
    byn: ' BYN',
    bzd: ' BZD',
    cad: ' CAD',
    cdf: ' CDF',
    chf: ' CHF',
    clp: ' CLP',
    cny: ' CNY',
    cop: ' COP',
    crc: ' CRC',
    cve: ' CVE',
    czk: ' CZK',
    djf: ' DJF',
    dkk: ' DKK',
    dop: ' DOP',
    dzd: ' DZD',
    egp: ' EGP',
    etb: ' ETB',
    fjd: ' FJD',
    fkp: ' FKP',
    gbp: ' GBP',
    gel: ' GEL',
    gip: ' GIP',
    gmd: ' GMD',
    gnf: ' GNF',
    gtq: ' GTQ',
    gyd: ' GYD',
    hkd: ' HKD',
    hnl: ' HNL',
    htg: ' HTG',
    huf: ' HUF',
    idr: ' IDR',
    ils: ' ILS',
    inr: ' INR',
    isk: ' ISK',
    jmd: ' JMD',
    jpy: ' JPY',
    kes: ' KES',
    kgs: ' KGS',
    khr: ' KHR',
    kmf: ' KMF',
    krw: ' KRW',
    kyd: ' KYD',
    kzt: ' KZT',
    lak: ' LAK',
    lbp: ' LBP',
    lkr: ' LKR',
    lrd: ' LRD',
    lsl: ' LSL',
    mad: ' MAD',
    mdl: ' MDL',
    mga: ' MGA',
    mkd: ' MKD',
    mmk: ' MMK',
    mnt: ' MNT',
    mop: ' MOP',
    mur: ' MUR',
    mvr: ' MVR',
    mwk: ' MWK',
    mxn: ' MXN',
    myr: ' MYR',
    mzn: ' MZN',
    nad: ' NAD',
    ngn: ' NGN',
    nio: ' NIO',
    nok: ' NOK',
    npr: ' NPR',
    nzd: ' NZD',
    pab: ' PAB',
    pen: ' PEN',
    pgk: ' PGK',
    php: ' PHP',
    pkr: ' PKR',
    pln: ' PLN',
    pyg: ' PYG',
    qar: ' QAR',
    ron: ' RON',
    rsd: ' RSD',
    rub: ' RUB',
    rwf: ' RWF',
    sar: ' SAR',
    sbd: ' SBD',
    scr: ' SCR',
    sgd: ' SGD',
    shp: ' SHP',
    sle: ' SLE',
    sos: ' SOS',
    srd: ' SRD',
    std: ' STD',
    szl: ' SZL',
    thb: ' THB',
    tjs: ' TJS',
    top: ' TOP',
    try: ' TRY',
    ttd: ' TTD',
    twd: ' TWD',
    tzs: ' TZS',
    uah: ' UAH',
    ugx: ' UGX',
    uyu: ' UYU',
    uzs: ' UZS',
    vnd: ' VND',
    vuv: ' VUV',
    wst: ' WST',
    xaf: ' XAF',
    xcd: ' XCD',
    xof: ' XOF',
    xpf: ' XPF',
    yer: ' YER',
    zar: ' ZAR',
    zmw: ' ZMW'
};

export default class CurrencyUtil {
    static formatCurrency(amount: number, currencyISO: string): string {
        return CurrencySymbol[currencyISO || CurrencySymbol.sek];
    }
    static formatCurrencySymbol(currencyISO: string): string {
        return `${CurrencySymbol[currencyISO] || CurrencySymbol.sek}`;
    }
}

export {};
