import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from './assets/Rentalbuddy_small.png'

const useStyles = makeStyles((theme: Theme) => ({
footerStyle: {
    marginTop: '16px',
    marginBottom: '0px',
    fontWeight: '400',
    lineHeight: '20px', 
},
linkStyle: {
    color: 'black', // Set text color to black
    textDecoration: 'underline'
  },
logoStyle: {
  height: '14px', // Set the same height for the image
  verticalAlign: 'middle', // Aligns the image with the text
  marginLeft: '3px'
}
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <>
        <div>
         <a href="https://rentalbuddy.io" target="_blank" rel="noreferrer" className={classes.linkStyle}>
           <h5 className={classes.footerStyle}>powered by
             <img src={logo} alt="Rentalbuddy logo" className={classes.logoStyle}></img>
           </h5>
         </a>
        </div>
        </>
    )

}

export default Footer