import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const supportedLanguages = ['en', 'sv', 'da', 'no', 'de', 'fr', 'es'];

function isLanguageSupported(language: string) {
    return supportedLanguages.includes(language);
}

function getNavigatorLanguage(): string {
    const browserLanguage = navigator?.language ? navigator.language.split('-')[0] : 'en';
    return isLanguageSupported(browserLanguage) ? browserLanguage : 'en';
}

// Wrap the i18n initialization logic in a function
export function initializeI18n() {
    const defaultLanguage = getNavigatorLanguage();

    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            lng: defaultLanguage,
            fallbackLng: defaultLanguage, // Fallback language
            // debug: true, // Enable debugging
            interpolation: {
                escapeValue: false
            },
            react: {
                useSuspense: false
            }
        });
}

export default i18n;
