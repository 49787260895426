import { Box, Skeleton, Stack, Typography } from '@mui/material';

export default function SkeletonStatusLoadingBottom() {
    return (
        <Stack>
            <Box sx={{ mb: 3, mt: 1 }}>
                <Skeleton variant="rectangular" height={77} width={'100%'} />
            </Box>
            <Box mb={3}>
                <Skeleton variant="rectangular" height={77} width={'100%'} />
            </Box>
            <Box mb={2}>
                <Skeleton variant="rectangular" height={77} width={'100%'} />
            </Box>
            <Typography variant="h3" width={'70%'}>
                <Skeleton />
            </Typography>
            <Typography variant="h3" width={'80%'}>
                <Skeleton />
            </Typography>
            <Skeleton width={'90%'} />
            <Skeleton width={'100%'} />
            <Typography variant="h5" width={'70%'}>
                <Skeleton />
            </Typography>
            <Skeleton width={'80%'} />
            <Skeleton width={'90%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'70%'} />
            <Skeleton width={'80%'} />
            <Skeleton width={'90%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'70%'} />
            <Skeleton width={'80%'} />
            <Skeleton width={'90%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'70%'} />
            <Skeleton width={'80%'} />
            <Skeleton width={'90%'} />
            <Skeleton width={'100%'} />
        </Stack>
    );
}
