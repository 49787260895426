import { Box, Skeleton, Stack } from '@mui/material';
import SkeletonEventContainer from './SkeletonEventContainer';
import SkeletonCardFooter from './SkeletonCardFooter';

export default function SkeletonEvents() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Stack sx={{ flexGrow: 1, ml: 2, mr: 2, mt: 1, maxHeight: 'calc(100%-170px)' }}>
                <Skeleton variant="rectangular" height={'25px'} width={'27%'} sx={{ borderRadius: '8px', mt: 1, mb: 1 }} />
                <SkeletonEventContainer />
                <SkeletonEventContainer />
                <SkeletonEventContainer />
                <SkeletonEventContainer />
                <SkeletonEventContainer />
                <SkeletonEventContainer />
            </Stack>
            <SkeletonCardFooter />
        </Box>
    );
}
