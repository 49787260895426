import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axios';
import { AccountInformation, BookingItem, LockerItem } from './interface';
import CancelledMessage from './CancelledMessage';
import ErrorMessage from './ErrorMessage';
import LockInformation from './LockInformation';
import { LockerTypeV1Alpha1, PinCodeResponse, Site } from '../product/interfaces';
import { AppBar, Box, Dialog, DialogContent, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../theme';
import LanguageSelector from '../language/LanguageSelector';
import LocalStorageUtil from '../../utils/localStorage.util';
import AppUtil from '../../utils/app.util';
import { ShowReturnContext } from '../return/ReturnContext';
import ReturnPage from '../return/ReturnPage';
import Footer from '../footer/Footer';
import axiosInstance from '../../utils/axios';
import SkeletonUnlockFrame from '../skeletons/SkeletonUnlockFrame';

const getLockerItem = async (lockerId) => {
    const lockerResponse = await axios.get(`booking/locker/${lockerId}`);
    return lockerResponse.data as LockerItem;
};

const getPinCodeResponseItem = async (bookingId) => {
    const pincCodeResponse = await axios.get(`booking/pincodes/${bookingId}`);
    return pincCodeResponse.data as PinCodeResponse[];
};

interface BookingProps {
    bookingId: string;
    showModal: boolean;
    closeModal: (onConfirmFunction?: () => void) => void;
    returnBookingBoolean: boolean;
    // bookingInfo: BookingItem;
    // lockerItems: LockerItem[];
    // pinCodesItems: PinCodeResponse[];
}

const UnlockFrame: React.FC<BookingProps> = ({ showModal, bookingId, closeModal, returnBookingBoolean }) => {
    const [bookingInfo, setBookingInfo] = useState<BookingItem | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [lockerItems, setLockerItems] = useState<LockerItem[]>([]);
    const [pinCodesItems, setPinCodesItems] = useState<PinCodeResponse[]>([]);
    const [siteData, setSiteData] = useState<Site | null>(null);
    const [error, setError] = useState(false);
    const [accountInformation, setAccountInformation] = useState(Object);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const selectedLanguageValue = LocalStorageUtil.getItem('user-language-selected') || 'en';
    const [showReturn, setShowReturn] = useState(returnBookingBoolean ?? false);

    const closeModalConfirm = () => {
        closeModal();
    };

    useEffect(() => {
        setShowReturn(returnBookingBoolean);
    }, [returnBookingBoolean]);

    const selectedLanguage = (event) => {
        AppUtil.changeLanguage(event);
    };

    async function fetchBookingData() {
        try {
            const response = await axios.get(`/booking/v1/booking/${bookingId}`);
            const booking = response.data as BookingItem[];
            if (booking.length > 0) {
                setBookingInfo(booking[0]);
                getSiteObject(booking[0].siteId);
                const lockerData: LockerItem[] = [];
                for (const product of booking[0].products) {
                    if (product.lockerId) {
                        const lockerItem = await getLockerItem(product.lockerId);
                        lockerData.push(lockerItem);
                    }
                }
                setLockerItems(lockerData);
                if (
                    lockerData.length > 0 &&
                    (lockerData[0].type === LockerTypeV1Alpha1.IGLOO_PADLOCK ||
                        lockerData[0].type === LockerTypeV1Alpha1.STORAGE_LOCK ||
                        lockerData[0].type === LockerTypeV1Alpha1.MANUAL_LOCKER)
                ) {
                    const pincCodeItems: PinCodeResponse[] = await getPinCodeResponseItem(bookingId);
                    setPinCodesItems(pincCodeItems);
                }
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
            // Handle error. Maybe set some state or redirect user.
        }
    }

    async function getAccountDetails() {
        try {
            const response = await axios.get(`sites/v1/contactInfo/${bookingInfo?.accountId}`);
            if (response.status === 200) {
                const data = response.data as AccountInformation;
                setAccountInformation(data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getSiteObject = useCallback(async (siteId) => {
        try {
            const response1 = await axiosInstance.get(`/sites/v1/getSiteDetails/${siteId}`);
            setSiteData((response1.data as Site) ?? null);
        } catch (e) {
            console.log(e);
        }

        return [];
    }, []);

    useEffect(() => {
        fetchBookingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId]);

    useEffect(() => {
        if (bookingInfo) {
            getAccountDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingInfo]);

    const languageToShowInBookingView = siteData?.languageToShowInBookingView ?? [];

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Unlock Page'
            });
        }
    }, []);

    return (
        <Dialog
            open={showModal}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullScreen={fullScreen}
            fullWidth
            sx={{ zIndex: 999, fontSize: '16px' }}
            PaperProps={{
                style: {
                    height: '100%'
                }
            }}>
            <AppBar position="sticky" color="transparent" sx={{ px: 2, pt: '8px', backgroundColor: '#ffffff' }}>
                <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            color="black"
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '1.5em',
                                mb: 0
                            }}>
                            {siteData?.name ? siteData.name : <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                        </Typography>
                    </Box>
                    <Box>
                        {languageToShowInBookingView.length > 0 && (
                            <LanguageSelector
                                languageValue={selectedLanguageValue}
                                selectedLanguage={(newLanguage) => selectedLanguage(newLanguage)}
                                languageToShowInBookingView={languageToShowInBookingView}
                            />
                        )}
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={closeModalConfirm}
                        sx={{
                            zIndex: 9999
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </AppBar>
            <DialogContent style={{ padding: 0, paddingBottom: 0, position: 'relative', minHeight: '360px' }}>
                <ShowReturnContext.Provider value={{ showReturn, setShowReturn }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '1rem'
                        }}>
                        {isLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                <SkeletonUnlockFrame />
                            </Box>
                        ) : bookingInfo?.status === 2 ? (
                            <CancelledMessage accountInformation={accountInformation} siteInformation={siteData} />
                        ) : showReturn ? (
                            <ReturnPage bookingId={bookingId} siteId={bookingInfo?.siteId ?? ''} />
                        ) : (
                            <>
                                {bookingInfo?.status === 1 && (
                                    <>
                                        {error && <ErrorMessage accountInformation={accountInformation} siteInformation={siteData} />}
                                        {!error && (
                                            <LockInformation
                                                bookingId={bookingId}
                                                lockerItems={lockerItems}
                                                pinCodesItems={pinCodesItems}
                                                setError={setError}
                                                accountInformation={accountInformation}
                                                bookingInfo={bookingInfo}
                                                siteInformation={siteData}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </ShowReturnContext.Provider>
            </DialogContent>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '1rem'
                }}>
                <Footer />
            </div>
        </Dialog>
    );
};

export default UnlockFrame;
