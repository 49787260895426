import React, { useCallback, useEffect, useState } from 'react';
import { BookingReceipt } from '../product/interfaces';
import axios from '../../utils/axios';
import { AppBar, Box, CircularProgress, Dialog, DialogContent, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material';
import LanguageSelector from '../language/LanguageSelector';
import OrderConfirm from './OrderConfirm';
import LocalStorageUtil from '../../utils/localStorage.util';
import theme from '../../theme';
import { removeURLParameter } from '../../utils/UrlHelper';
import AppUtil from '../../utils/app.util';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyUtil from '../../utils/currency.util';
import axiosInstance from '../../utils/axios';

interface Props {
    orderId: string;
    showModal: boolean;
    closeModal: (onConfirmFunction?: () => void) => void;
}

export default function ShowConfirmFrame({ orderId, showModal, closeModal }: Props) {
    const [receipt, setReceipt] = React.useState<BookingReceipt | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const selectedLanguageValue = LocalStorageUtil.getItem('user-language-selected') || 'en';
    const [currencyISO, setCurrencyISO] = useState<string>('');

    const closeModalConfirm = () => {
        closeModal();
    };

    const selectedLanguage = (newLanguage) => {
        LocalStorageUtil.setItem('user-language-selected', newLanguage);
        AppUtil.changeLanguage(newLanguage);
    };

    const getOrderReceipt = useCallback(async (orderId) => {
        setIsLoading(true);
        try {
            const response1 = await axiosInstance.get(`/payment/v1/bookingReceipt/${orderId}`);
            return response1.data as BookingReceipt;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, []);

    const fetchCurrency = async (receiptItem: BookingReceipt) => {
        try {
            const response = await axios.get(`/sites/v1/contactInfo/${receiptItem?.site!.accountId}`, {});
            const code = response.status;
            if (code >= 200 && code < 300) {
                const currencyISO = response.data.currency || ' SEK';
                setCurrencyISO(CurrencyUtil.formatCurrencySymbol(currencyISO));
            } else {
                setCurrencyISO(CurrencyUtil.formatCurrencySymbol(currencyISO));
            }
        } catch (error) {
            console.error('Failed to fetch currency', error);
            setCurrencyISO(CurrencyUtil.formatCurrencySymbol(currencyISO));
        }
    };

    useEffect(() => {
        if (orderId) {
            const receiptPromise = getOrderReceipt(orderId);
            receiptPromise.then((newReceipt: BookingReceipt | null) => {
                if (newReceipt) {
                    fetchCurrency(newReceipt);
                    setReceipt(newReceipt);
                    setIsLoading(false);

                    // Remove query-parameters coming from acceptUrl
                    let newUrl = removeURLParameter(window.location.toString(), 'orderId');
                    newUrl = removeURLParameter(newUrl, 'showConfirm');
                    window.history.pushState({}, document.title, newUrl);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    const languageToShowInBookingView = receipt?.site?.languageToShowInBookingView ?? [];

    return (
        <Dialog
            open={showModal}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullScreen={fullScreen}
            fullWidth
            sx={{ zIndex: 999, fontSize: '16px' }}
            PaperProps={{
                style: {
                    height: '100%'
                }
            }}>
            <AppBar position="sticky" color="transparent" sx={{ px: 2, pt: '8px', backgroundColor: '#ffffff' }}>
                <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            color="black"
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '1.5em',
                                mb: 0
                            }}>
                            {receipt?.site.name ? receipt?.site.name : <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                        </Typography>
                    </Box>
                    <Box>
                        {languageToShowInBookingView.length > 0 && (
                            <LanguageSelector
                                languageValue={selectedLanguageValue}
                                selectedLanguage={(newLanguage) => selectedLanguage(newLanguage)}
                                languageToShowInBookingView={languageToShowInBookingView}
                            />
                        )}
                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={closeModalConfirm}
                        sx={{
                            zIndex: 9999
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </AppBar>
            <DialogContent style={{ padding: 0, paddingBottom: 0, position: 'relative', minHeight: '360px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '1rem'
                    }}>
                    {isLoading || !receipt || !currencyISO ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                flexDirection: 'column'
                            }}>
                            <CircularProgress color={'primary'} size={'small'} sx={{ width: '20px' }} />
                        </Box>
                    ) : (
                        <>
                            <Box style={{ padding: 0, paddingBottom: 0, position: 'relative' }}>
                                <OrderConfirm receipt={receipt} currencyISO={currencyISO} />
                            </Box>
                        </>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
