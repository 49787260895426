/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box, AppBar, IconButton } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import { Product, ProductTypes, Site } from '../product/interfaces';
import InsuranceCard from '../product/InsuranceCard';
import ProductCard from '../product/ProductCard';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import Footer from '../footer/Footer';
import { formatTime } from '../../utils/dateHelper';

interface Props {
    selectedProducts: Product[];
    availableProducts: Product[];
    setSelectedProducts: (products: Product[]) => void;
    goToPrevStep: () => void;
    goToNextStep: () => void;
    dateRange: [null | Date, null | Date];
    pickupTime: string | null;
    returnTime: string | null;
    currencyISO: string;
    timeSettingAmPm: boolean;
    timeZone: string;
}

const AddonsSelection = ({
    selectedProducts,
    setSelectedProducts,
    availableProducts,
    goToNextStep,
    goToPrevStep,
    dateRange,
    pickupTime,
    returnTime,
    currencyISO,
    timeSettingAmPm,
    timeZone
}: Props) => {
    const addProduct = (product: Product, quantity: number) => {
        const selectedProductsReturn = [...selectedProducts];
        const objIndex = selectedProductsReturn.findIndex((selectedProduct) => selectedProduct.id === product.id);
        if (objIndex >= 0) {
            selectedProductsReturn[objIndex].quantity += quantity;
            // Update quantity
        } else {
            // Push
            product.quantity = quantity;
            selectedProductsReturn.push(product);
        }
        //Google analytics 4
        if (typeof window.gtag === 'function') {
            const itemsForGTM = [
                {
                    item_id: product.id,
                    item_name: typeof product.name === 'string' ? product.name : product.name.en, // product.name,
                    price: product.cost,
                    quantity: quantity,
                    item_category: 'Accessory'
                }
            ];
            window.gtag('event', 'add_to_cart', {
                currency: currencyISO.trim(),
                value: product.cost,
                items: itemsForGTM
            });
        }
        setSelectedProducts(selectedProductsReturn);
    };

    const { t } = useTranslation();
    const addInsurrance = (insurance: Product) => {
        const selectedProductsReturn = [...selectedProducts];
        const objIndex = selectedProductsReturn.findIndex((selectedProduct) => selectedProduct.id === insurance.id);
        let eventType = '';

        if (objIndex >= 0) {
            // Insurance is already in the cart, so remove it
            selectedProductsReturn.splice(objIndex, 1);
            eventType = 'remove_from_cart';
        } else {
            // Insurance not in the cart, add it
            insurance.quantity = 1;
            selectedProductsReturn.push(insurance);
            eventType = 'add_to_cart';
        }

        if (typeof window.gtag === 'function') {
            // Track the event
            const itemsForGTM = [
                {
                    item_id: insurance.id,
                    item_name: typeof insurance.name === 'string' ? insurance.name : insurance.name.en,
                    price: insurance.cost,
                    quantity: insurance.quantity,
                    item_category: 'Insurance'
                }
            ];

            window.gtag('event', eventType, {
                currency: currencyISO.trim(),
                value: insurance.cost,
                items: itemsForGTM
            });
        }

        setSelectedProducts(selectedProductsReturn);
    };

    const getProductAccessories = (productIds: string[]) => {
        const accessories = availableProducts.filter((product) => productIds.includes(product.id));
        return accessories;
    };

    const visibleInsurances = useMemo(() => {
        return availableProducts.filter(
            (item) => item.type === ProductTypes.INSURANCE && !item.forcingFee
        );
    }, [availableProducts]);

    const pickupText = useMemo(() => {
        let text = 'YYYY/MM/DD';
        if (dateRange[0]) {
            const dateText = moment.tz(dateRange[0], timeZone).format('YYYY/MM/DD');
            // Only append the time if a valid time has been selected
            if (pickupTime) {
                if (pickupTime.includes('AM') || pickupTime.includes('PM')) {
                    return (text = `${dateText} ${pickupTime}`);
                }
                const formattedPickupTime = formatTime(pickupTime, timeSettingAmPm);
                text = `${dateText} ${formattedPickupTime}`;
                if (pickupTime && !timeSettingAmPm) {
                    text = `${dateText} ${pickupTime}`;
                }
            } else if (pickupTime) {
                text = `${dateText} ${pickupTime}`;
            } else {
                text = dateText;
            }
        }
        return text;
    }, [dateRange, pickupTime, timeSettingAmPm]);

    const returnText = useMemo(() => {
        let text = 'YYYY/MM/DD';
        if (dateRange[1]) {
            const dateText = moment.tz(dateRange[1], timeZone).format('YYYY/MM/DD');
            // Only append the time if a valid time has been selected
            if (returnTime && timeSettingAmPm) {
                if (returnTime.includes('AM') || returnTime.includes('PM')) {
                    return (text = `${dateText} ${returnTime}`);
                }
                const formattedReturnTime = formatTime(returnTime, timeSettingAmPm);
                text = `${dateText} ${formattedReturnTime}`;
                if (returnTime && !timeSettingAmPm) {
                    text = `${dateText} ${returnTime}`;
                }
            } else if (returnTime) {
                text = `${dateText} ${returnTime}`;
            } else {
                text = dateText;
            }
        }
        return text;
    }, [dateRange, returnTime, timeSettingAmPm]);

    useEffect(() => {
        const itemListInsurance = availableProducts.filter((item) => item.type === ProductTypes.INSURANCE);
        const itemListAccessory = availableProducts.filter((item) => item.type === ProductTypes.ACCESSORY);

        if (itemListInsurance.length > 0) {
            if (typeof window.gtag === 'function') {
                // Prepare the items array for GTM
                const itemsForGTM = itemListInsurance.map((item) => ({
                    item_id: item.id,
                    item_name: typeof item.name === 'string' ? item.name : item.name.en, // item.name, // REQUIRED
                    price: item.price
                }));

                window.gtag('event', 'view_item_list', {
                    item_list_name: 'Insurance',
                    items: itemsForGTM
                });
            }
        }
        if (itemListAccessory.length > 0) {
            if (typeof window.gtag === 'function') {
                const itemsForGTM = itemListAccessory.map((item) => ({
                    item_id: item.id,
                    item_name: typeof item.name === 'string' ? item.name : item.name.en, // item.name, // REQUIRED
                    price: item.price
                }));
                window.gtag('event', 'view_item_list', {
                    item_list_name: 'Accessorys',
                    items: itemsForGTM
                });
            }
        }
    }, [availableProducts]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Addon Page'
            });
        }
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ p: 2, position: 'relative', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <CalendarTodayIcon fontSize="small" color="action" sx={{ fontSize: '0.8em' }} />
                        <Typography variant="body1" color="#0000008a" sx={{ px: 1, fontSize: '12px' }}>
                            {pickupText}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <CalendarTodayIcon fontSize="small" color="action" sx={{ fontSize: '0.8em' }} />
                        <Typography variant="body1" color="#0000008a" sx={{ px: 1, fontSize: '12px' }}>
                            {returnText}
                        </Typography>
                    </Box>
                </Box>

                {visibleInsurances.length > 0 && (
                <Typography variant="h4" gutterBottom color="black" sx={{ mt: 3, mb: 0 }}>
                    {t('translation.addonsSelection.insurance')}
                </Typography>
            )}

            {visibleInsurances.map((item, index) => {
                const isAddedToCart = selectedProducts.findIndex((selectedProduct) => selectedProduct.id === item.id) >= 0;
                return (
                    <InsuranceCard
                        key={item.id}
                        insurance={item}
                        isAddedToCart={isAddedToCart}
                        addInsurrance={addInsurrance}
                        selectedProducts={selectedProducts}
                        currencyISO={currencyISO}
                        />
                    );
                })}

                {selectedProducts
                    .filter((product) => product.accessories && product.accessories.length > 0)
                    .map((item, index) => (
                        <div key={item.id}>
                            <Typography variant="h4" gutterBottom color="primary" sx={{ mt: 2 }}>
                                {translate(item.name, getCurrentLanguageFromLocalStorage())}
                            </Typography>
                            {getProductAccessories(item.accessories).map((accessory, i) => (
                                <ProductCard
                                    product={accessory}
                                    key={accessory.id}
                                    addProduct={addProduct}
                                    selectedProduct={selectedProducts.find((prod) => prod.id === accessory.id)}
                                    currencyISO={currencyISO}
                                />
                            ))}
                        </div>
                    ))}
                {availableProducts.filter((item) => item.type === ProductTypes.ACCESSORY).length > 0 && (
                    <Typography variant="h4" gutterBottom color="black" sx={{ mt: 2, mb: 2 }}>
                        {t('translation.addonsSelection.title')}
                    </Typography>
                )}
                {/* CRASH ON Immeln for accessories */}
                {availableProducts
                    .filter((item) => item.type === ProductTypes.ACCESSORY)
                    .map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ProductCard
                            product={item}
                            key={item.id}
                            addProduct={addProduct}
                            selectedProduct={selectedProducts.find((prod) => prod.id === item.id)}
                            currencyISO={currencyISO}
                        />
                    ))}
            </Box>
            <AppBar
                position="sticky"
                color="transparent"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    textAlign: 'center',
                    py: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    px: 2,
                    zIndex: 9999
                }}>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            goToPrevStep();
                        }}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        sx={{ borderRadius: 20, height: 40, px: 4, flex: 1 }}
                        onClick={() => {
                            goToNextStep();
                        }}>
                        {t('translation.addonsSelection.goToCartButton')}
                    </Button>
                </Box>
                <Footer />
            </AppBar>
        </Box>
    );
};

export default AddonsSelection;
