import React, { useEffect, useState } from 'react';
import moment from 'moment';

interface Props {
    targetDate: string | Date;
    expireFunction: () => void;
}

const CountdownTimerInMinutes = ({ targetDate, expireFunction }: Props) => {
    const [timeLeft, setTimeLeft] = useState(moment(targetDate).diff(moment()));

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeLeft(moment(targetDate).diff(moment()));
        }, 1000);
        return () => clearInterval(timerId);
    }, [targetDate]);

    useEffect(() => {
        if (timeLeft <= 0 && expireFunction) {
            expireFunction();
        }
    }, [timeLeft, expireFunction]);

    if (timeLeft <= 0) {
        return <span>00:00</span>;
    }

    const duration = moment.duration(timeLeft);
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const formattedTimeLeft = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return <span>{formattedTimeLeft}</span>;
};

export default CountdownTimerInMinutes;
