import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';

interface CustomFieldDropdownProps {
    customField: ICustomField;
    onChange: Function;
    control: Control;
    errors: any;
}

export default function CustomFieldDropdown({ customField, onChange, control, errors }: CustomFieldDropdownProps) {
    const [val, setVal] = useState<string>('');

    useEffect(() => {
        setVal('');
        onChange(customField, '');
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        setVal(event.target.value);
        onChange(customField, event.target.value);
    };

    return (
        <Controller
            name={customField.id}
            control={control}
            defaultValue=''
            rules={{
                required: customField.isMandatory
            }}
            render={({ field }) => (
                <FormControl 
                    variant="outlined" 
                    size="small"
                    error={Boolean(errors[customField.id])}
                    key={customField.id}>
                    <InputLabel id={customField.id}>{customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}</InputLabel>
                    <Select
                        labelId={customField.id}
                        id={customField.id}
                        label={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                        {...field}
                        error={Boolean(errors[customField.id])}
                    >
                        {customField.options?.map((o) => <MenuItem key={o.id} value={o.value}>{o.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? o.name['sv']}</MenuItem>)}
                    </Select>
                    {errors[customField.id] && (
                        <FormHelperText error>
                            {errors[customField.id]?.message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}
