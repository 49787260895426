import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    clientSecret: string;
    publicStripeKey: string;
    orderId: string;
}

export default function PayStep({ clientSecret, publicStripeKey, orderId }: Props) {
    const { t } = useTranslation();
    const stripePromise = loadStripe(publicStripeKey);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        stripePromise.then(() => {
            // Simulate a loading delay
            setTimeout(() => setIsLoading(false), 1000); // Adjust the delay as needed
        });

        if (!clientSecret || !publicStripeKey) {
            setIsLoading(false);
        }
    }, [stripePromise, orderId, clientSecret, publicStripeKey]);

    return (
        <>
            {clientSecret && stripePromise && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                        clientSecret
                    }}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}

            {isLoading && (
                <CircularProgress
                    color="primary"
                    size="medium"
                    sx={{ width: '100px', position: 'absolute', top: '50%', left: 'calc(50% - 50px)', transform: 'translate(-50%, -50%)' }}
                />
            )}
            {(!clientSecret || !publicStripeKey) && <h2 style={{ padding: '16px' }}>{t('translation.paymentStep.missingStripeImplementation')}</h2>}
        </>
    );
}
