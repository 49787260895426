import React from 'react';
import { AccountInformation } from './interface';
import { useTranslation } from 'react-i18next';
import { Site } from '../product/interfaces';

interface Props {
    accountInformation: AccountInformation;
    siteInformation: Site | null;
}

export default function ErrorMessage({ accountInformation, siteInformation }: Props) {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column'
            }}>
            <h1>{t('translation.unlock.errorTitle')}</h1>
            <h3>
                {t('translation.unlock.errorMessage', {
                    0: accountInformation.contactPersonName,
                    1: siteInformation?.name,
                    2: siteInformation?.phone
                })}
            </h3>
        </div>
    );
}
