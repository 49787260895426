import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import React from 'react';
import { LockerTypeV1Alpha1, PinCodeResponse } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import PinCodeUtil from '../../utils/pincode.util';
import { t } from 'i18next';

interface Props {
    pinData: PinCodeResponse;
}

export const PincodeListItem = ({ pinData }: Props) => {
    const formatPin = (pinCode: string) => {
        const divide = 3;
        return PinCodeUtil.formatPinCode(pinCode, divide);
    };

    let row1 = `${translate(pinData.productName, getCurrentLanguageFromLocalStorage())}`;
    let row2 = '';
    let row3 = `${t('translation.orderConfirmation.pinCode')}: ${formatPin(pinData.pinCode)}`;

    if (pinData.lockerType !== LockerTypeV1Alpha1.STORAGE_LOCK && pinData.lockerType !== LockerTypeV1Alpha1.STORAGE_MANUAL_LOCKER) {
        row2 = `${t('translation.orderConfirmation.lock')}: ${pinData.lockerNumber}`;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '0.5em 1em',
                borderRadius: '20px',
                m: '0.5rem 0',
                border: '1px solid #bdbdbd',
                width: '100%'
            }}>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '15%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    '@media (min-width: 600px)': {
                        minWidth: '28%' // Set minWidth to '33%' when width is larger than 'xs'
                    },
                    // '@media (max-width: 400px)': {
                    //     fontSize: '12px'
                    // },
                }}>
                {row1}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '15%',
                    '@media (min-width: 600px)': {
                        minWidth: '28%',
                    },
                    // '@media (max-width: 400px)': {
                    //     fontSize: '12px'
                    // },
                }}>
                {row2}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap', // Prevent wrapping
                    alignItems: 'center', // Align items vertically
                    justifyContent: 'center', // Adjust this as needed
                    textAlign: 'center',
                    fontWeight: 'bold', 
                    minWidth: '46%',
                    fontSize: 14,
                    '@media (min-width: 600px)': {
                        minWidth: '43%'
                    }
                }}>
                {row3} <LockOpenIcon sx={{ fontSize: 14, transform: 'scaleX(-1)', marginLeft: '2px' }}/>
            </Typography>
        </Box>
    );
};
