import { Box, Skeleton, Typography } from '@mui/material';

export default function SkeletonUnlockFrame() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%' }}>
            <Typography variant="h1" width={'90%'} mb={2} mt={1.5}>
                <Skeleton />
            </Typography>
            <Skeleton variant="rectangular" height={40} width={'100%'} sx={{ borderRadius: '200px', mb: 3 }} />
            <Skeleton width={'95%'} />
            <Typography variant="h2" mt={3.5} width={'45%'}>
                <Skeleton />
            </Typography>
            <Typography variant="h2" width={'50%'}>
                <Skeleton />
            </Typography>
            <Skeleton variant="rectangular" height={40} width={'161px'} sx={{ borderRadius: '200px', mt: 2, mb: 3 }} />
            <Skeleton width={'85%'} />
            <Skeleton width={'60%'} />
        </Box>
    );
}
