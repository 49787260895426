import { Typography, Box, Switch } from '@mui/material';
import { Product } from './interfaces';
import ProductHelpers from './ProductHelpers';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import InfoTooltip from './InfoTooltip';

interface Props {
    insurance: Product;
    isAddedToCart: boolean;
    addInsurrance: (insurance: Product) => void;
    selectedProducts: Product[];
    currencyISO: string;
}

const InsuranceCard = ({ insurance, addInsurrance, isAddedToCart, selectedProducts, currencyISO }: Props) => {
    return (
        <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
            <InfoTooltip insurance={insurance} />
            <Typography variant="caption" sx={{ flex: 1, color: '#000000', lineHeight: '12px' }}>
                {translate(insurance.name, getCurrentLanguageFromLocalStorage())}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500, mr: 1.5 }}>
                {ProductHelpers.getInsurancePrice(insurance, selectedProducts)}
                {currencyISO}
            </Typography>
            <Switch
                checked={isAddedToCart}
                color="primary"
                onChange={() => {
                    // Add/Remove insurrance from cart
                    addInsurrance(insurance);
                }}
            />
        </Box>
    );
};

export default InsuranceCard;
