import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';

interface CustomFieldCheckboxProps {
    customField: ICustomField;
    onChange: Function;
    control: Control;
    errors: any;
}

export default function CustomFieldCheckbox({ customField, onChange, control, errors }: CustomFieldCheckboxProps) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(false);
        onChange(customField, false);
    }, []);

    let handleChange = () => {
        onChange(customField, !isChecked);
        setIsChecked(!isChecked);
    };

    return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Controller
                name={customField.id}
                control={control}
                defaultValue=''
                rules={{
                    required: customField.isMandatory
                }}
                render={({ field }) => (
                    <FormControl
                        key={customField.id}
                        error={Boolean(errors[customField.id])}>
                        <FormControlLabel
                        label={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                        control={
                            <Checkbox
                            required={customField.isMandatory}
                            checked={isChecked}
                            {...field}
                            onChange={handleChange}
                        />
                        } />
                        {errors[customField.id] && (
                        <FormHelperText error>
                            {errors[customField.id]?.message}
                        </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
        </Stack>
    );
}
