import { useEffect, useState } from 'react';
import i18n from '../i18n';


export interface LanguageTextTypeV1Alpha1 {
    [key: string]: string;
}

export function translate(text: any | string, lang: string) {
    try {
        if (typeof text === 'string') {
            return text;
        }
        const map = new Map(Object.entries(text));
        const translation = map.get(lang);

        if (translation !== undefined) {
            return translation as string;
        }

        return (map.get(navigator?.language ?? 'en') as string) || '';
    } catch (e) {
        console.log(e);
        return '';
    }
}

export function getCurrentLanguageFromLocalStorage(): string {
    return i18n.language ?? 'en';
}

export function useSelectedLanguage() {
    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguageFromLocalStorage());

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.detail.key === 'user-language-selected') {
                setSelectedLanguage(e.detail.newValue || 'en');
            }
        };

        window.addEventListener('customStorageChange', handleStorageChange);

        return () => {
            window.removeEventListener('customStorageChange', handleStorageChange);
        };
    }, []);

    return selectedLanguage;
}