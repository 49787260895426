import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

interface Props {
    productName: string;
    lockNumber: number | string;
    pinCode?: string;
}

export default function RegularLockItem({ productName, lockNumber, pinCode }: Props) {
    const { t } = useTranslation();
    const [lockNumberText, setLockNumberText] = React.useState(`${t('translation.orderConfirmation.lock')}: ${lockNumber}`);
    useEffect(() => {
        if(pinCode) {
            setLockNumberText(`${t('translation.orderConfirmation.lock')}: ${lockNumber}
                (${t('translation.orderConfirmation.pinCode')}: ${pinCode})`);
        }
    }, [lockNumber, pinCode, t]);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '0.5em 1em',
                borderRadius: '20px',
                m: '0.5rem 0',
                border: '1px solid #bdbdbd',
                width: '100%'
            }}>
            <Typography
                variant="body2"
                sx={{
                    textAlign: 'left',
                    minWidth: '55%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}>
                {productName}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: 'bold', minWidth: '45%' }}>
                {lockNumberText}
            </Typography>
        </Box>
    );
}
