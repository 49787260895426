import { Box, Skeleton, Stack } from '@mui/material';

export default function SkeletonAppBarTwoSteps() {
    return (
        <Stack spacing={0} width={'100%'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton variant="circular" width={40} height={40}></Skeleton>

                <Skeleton variant="rectangular" width={'41%'} height={3}></Skeleton>
                <Skeleton variant="circular" width={40} height={40}></Skeleton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mb: '6px', mt: 0 }}>
                <Skeleton width={40}></Skeleton>

                <Skeleton variant="rectangular" width={'41%'} height={0}></Skeleton>
                <Skeleton width={40}></Skeleton>
            </Box>
        </Stack>
    );
}
