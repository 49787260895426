import { Box, Skeleton, Stack, Typography } from '@mui/material';
import SkeletonCardFooter from './SkeletonCardFooter';

export default function SkeletonBookingDefault() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Stack sx={{ flexGrow: 1, ml: 2, mr: 2, mt: 1, maxHeight: 'calc(100%-170px)' }}>
                <Box sx={{ mt: 1, mb: 2, display: 'flex', gap: 1, width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'Column', gap: '6px', width: '100%' }}>
                        <Skeleton width={60}></Skeleton>
                        <Skeleton variant="rectangular" height={80} width={'100%'} sx={{ borderRadius: '12px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'Column', gap: '6px', width: '100%' }}>
                        <Skeleton width={60}></Skeleton>
                        <Skeleton variant="rectangular" height={80} width={'100%'} sx={{ borderRadius: '12px' }} />
                    </Box>
                </Box>
            </Stack>
            <SkeletonCardFooter />
        </Box>
    );
}
