import { AppBar, Box, Skeleton, Stack, Typography } from '@mui/material';
import SkeletonCardFooter from './SkeletonCardFooter';
import SkeletonAppBarThreeSteps from './SkeletonAppBarThreeSteps';
import SkeletonAppBarTop from './SkeletonAppBarTop';

export default function SkeletonQrFlow() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflowY: 'hidden'
            }}>
            <AppBar position="sticky" color="transparent" sx={{ px: 2, pt: '14px', backgroundColor: '#ffffff', height: '126px' }}>
                <Box sx={{ display: 'flex', mb: 1.5, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <SkeletonAppBarTop />
                    <Skeleton variant="circular" width={30} height={30} sx={{ mr: 1 }} />
                </Box>
                <SkeletonAppBarThreeSteps />
            </AppBar>

            <Box
                style={{
                    padding: '16px',
                    paddingBottom: 0,
                    position: 'relative',
                    minHeight: '360px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                <Stack sx={{ flexGrow: 1, maxHeight: 'calc(100%-170px)' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', mb: 1 }}>
                        <Skeleton width={124} sx={{ mr: 2 }}></Skeleton>
                        <Skeleton width={124} sx={{ ml: 2 }}></Skeleton>
                    </Box>
                    <Typography variant="h2" width={'80%'} mx={'auto'}>
                        <Skeleton />
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', mb: 2, mt: 1, overflowX: 'hidden', width: '120%' }}>
                        <Skeleton variant="rectangular" height={'38px'} width={'71px'} sx={{ borderRadius: '200px', mt: 1 }} />
                        <Skeleton variant="rectangular" height={'38px'} width={'64px'} sx={{ borderRadius: '200px', mt: 1 }} />
                        <Skeleton variant="rectangular" height={'38px'} width={'69px'} sx={{ borderRadius: '200px', mt: 1 }} />
                        <Skeleton variant="rectangular" height={'38px'} width={'64px'} sx={{ borderRadius: '200px', mt: 1 }} />
                        <Skeleton variant="rectangular" height={'38px'} width={'69px'} sx={{ borderRadius: '200px', mt: 1 }} />
                        <Skeleton variant="rectangular" height={'38px'} width={'69px'} sx={{ borderRadius: '200px', mt: 1 }} />
                    </Box>

                    <Skeleton variant="rectangular" height={60} width={'100%'} sx={{ mb: '8px' }} />
                    <Skeleton variant="rectangular" height={1} width={'100%'} />
                    <Skeleton variant="rectangular" height={60} width={'100%'} sx={{ my: '8px' }} />
                    <Skeleton variant="rectangular" height={1} width={'100%'} sx={{ mb: '24px' }} />

                    <Skeleton width={140} sx={{ ml: 'auto' }} />
                    <Skeleton width={'50%'} sx={{ ml: 'auto' }} />

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', mb: 1, mt: 1 }}>
                        <Skeleton variant="rectangular" height={'40px'} width={'calc(100% - 90px)'} sx={{ borderRadius: '4px' }} />
                        <Skeleton variant="rectangular" height={'40px'} width={'90px'} sx={{ borderRadius: '4px' }} />
                    </Box>

                    <Skeleton width={'40%'} />

                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />
                    <Skeleton variant="rectangular" height={'40px'} width={'100%'} sx={{ mt: 1, borderRadius: '4px' }} />

                    <Skeleton width={'80%'} />
                    <Skeleton width={'100%'} />
                    <Skeleton width={'90%'} />

                    <Skeleton variant="rectangular" height={'100px'} width={0} sx={{ mt: 1, borderRadius: '4px' }} />
                </Stack>
                <SkeletonCardFooter />
            </Box>
        </Box>
    );
}
