import { Product, ProductTypes } from './interfaces';

export default class ProductHelpers {
  static getInsurancePrice = (insurance: Product, selectedProducts: Product[]) => {
    const rentalProducts = selectedProducts.filter(
      (product) => product.type === ProductTypes.RENTAL
    );
    const numberOfRentalProducts = rentalProducts
      .map((product) => product.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);

    return insurance.cost * numberOfRentalProducts;
  };
}
