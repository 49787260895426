import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';

const ReviewCard = ({ review }) => (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', borderRadius: 5, backgroundColor: 'white' }}>
        <Box
            sx={{
                borderRadius: '50%',
                maxHeight: 32,
                maxWidth: 32,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
            <img
                src={review.profile_photo_url || 'https://place-hold.it/300'}
                alt={`${review.author_name}'s Google profile img`}
                style={{ width: '100%' }}
            />
        </Box>
        <Box sx={{ flexDirection: 'column', textAlign: 'left', mx: 1 }}>
            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                {review.author_name}
            </Typography>
            <Rating
                name="read-only"
                value={review.rating}
                precision={0.1}
                size="medium"
                readOnly
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                sx={{ ml: 0 }}
            />
            <Typography variant="body2" sx={{ lineHeight: 'normal', margin: 0, color: '#808080' }}>
                {review.relative_time_description}
            </Typography>
            <Typography variant="body2" component="div" sx={{ mt: 1 }}>
                {review.text}
            </Typography>
        </Box>
    </Box>
);

export default ReviewCard;