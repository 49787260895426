import React from 'react';

interface MarkerProps {
  lat: number;
  lng: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Marker: React.FC<MarkerProps> = ({ lat, lng }) => (
  <img
    style={{
      width: '26px',
      height: '43px',
      position: 'absolute',
      top: '-43px',
      left: '-13px'
    }}
    alt="SiteImage"
    src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAArCAYAAACJrvP4AAAC+ElEQVR4AWIgBP7//88DxCGAWssB1pIkCsO1iLG27WCtYG17x3Ywtm3bthlNMLZtz/NrXvStM/cbP/Vln6RSOfq/xqlOi2nO10bxUe26RRKLRdnxiZOnTqVpQO6NOc5AiYSd6O4dRcbAnlJQv4rk/fWt5HzzkbDjEydPHfX0pQr6Q4dcI7RqqZP7Z1z8i/cSLuqop4/+ZCB3iOt214UFVkG9SoikvOijHx1fGAXeqWNm7u9f0Zj2oh+dCoHcOlfkA0oZiB66ZYaBZ53uo/N7pOiWGBqmyF21xKEg24uhQf/mOWJsmaYgYOiiDwfYn5yTRE2X42vNWy/KyKcelZ6PPMCOTzwhEH04ii8ABzMRaNwzj0uXB+8rs4gnAqIPR/HJ4UvgV8wdlICUXOR9+9GHo/jG8enxK+aR+cDI+/ajD0eJ50Vzvv3Yt5h35Acj79ePPhylbetyXuVfA70z9OEoXVy0pbBl40DfGfpwVMy1R1kTRnpBTqM1cZQHh3P2Tiwv1wzynKEPR2HaNI4VNmsQyBcE3bj+UTgYE1kncmCvFQQMXRGpfQsmcre27fNFHVtkFYSeOM459OHcDvw+/mwtzkQ2QOigh64qz7RlrXRmTw1nA4ZOXG+F3z/Iozrkmvm1/80IRD866CkfY1hqeSeOGZnA6EdHJWPaNjdYw/tH0wHRR38q/43Padcx8/75ISUQ9fTRr1IxiUZbR7ZtSulxUk9fsoySZ8+xjxZ1bpkUiDrq6Uv3f/9TXVxk53z3qS+IPHXUq0xMbHuRNWGk77CQp05laiLysoRCbu4vn5cLIk6eOpUN06a52J46zisPRpy8ypaJyOvaMqycrz4sAcInTl5l07RprC3u1qYEDJ+4yraJSI3w5vVmiXO1dYNJPAjYQzo+CDlfvi+A2PGJqyBMG8aegiY1BRg7vgrKYqY53RjUS4Cx4wcGk1isa3zUNTB2/OBgIvVDq5bawNjxg4R96Z07U5T393fCjh8k7E7t2PPEi7rs+Kn0XwF82Z38cjx5pwAAAABJRU5ErkJggg=='}
  />
);

export default Marker;
