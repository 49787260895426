import { AppBar, Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from '../event/EventCard';
import { BookableEventV1, EventV1 } from '../product/interfaces';
import Footer from '../footer/Footer';
interface Props {
    events: EventV1[];
    selectedEventOccations: BookableEventV1[];
    setSelectedEventOccations: React.Dispatch<React.SetStateAction<BookableEventV1[]>>;
    goToCart: () => void;
    eventQuantity: number;
    currencyISO: string;
    timeSettingAmPm: boolean;
}

export type PriceGroupQuantityType = {
    [key: string]: number;
};

export default function EventSelection({ events, selectedEventOccations, setSelectedEventOccations, goToCart, eventQuantity, currencyISO, timeSettingAmPm }: Props) {
    const { t } = useTranslation();

    const addEvent = (
        modifications: Array<{
            eventOccation: BookableEventV1;
            quantity: number;
            eventOccationCost: number;
            vat: number;
            priceGroup?: string;
        }>
    ) => {
        setSelectedEventOccations((prevSelectedEventOccations) => {
            let updatedEventOccasions = [...prevSelectedEventOccations];

            for (let mod of modifications) {
                const objIndex = updatedEventOccasions.findIndex(
                    (selectedEvent) => selectedEvent.id === mod.eventOccation.id && selectedEvent.priceGroup === mod.priceGroup
                );

                if (objIndex >= 0) {
                    updatedEventOccasions[objIndex].quantity += mod.quantity;
                    updatedEventOccasions[objIndex].info = mod.eventOccation.info;
                    updatedEventOccasions[objIndex].priceGroup = mod.priceGroup;
                } else {
                    const newEventOccation = { ...mod.eventOccation };
                    newEventOccation.quantity = mod.quantity;
                    newEventOccation.cost = mod.eventOccationCost;
                    newEventOccation.vat = mod.vat;
                    newEventOccation.priceGroup = mod.priceGroup;
                    updatedEventOccasions.push(newEventOccation);
                }
            }

            return updatedEventOccasions;
        });
    };

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Event Page',
            });
        }
    }, []);

    useEffect(() => {   
        if (typeof window.gtag === 'function' && events.length > 0) {
          // Prepare the items array for GTM
          const itemsForGTM = events.map(item => ({
            item_id: item.id,
            item_name: typeof item.name === 'string' ? item.name : item.name.en,
            quantity: item.inventory
          }));
          window.gtag('event', 'view_item_list', {
            item_list_name: "Events",
            items: itemsForGTM,
          });     
        }
      }, [events]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ p: 2, pb: 0, position: 'relative', flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom color="primary">
                    {t('translation.eventSelection.title')}
                </Typography>
                {events
                    .slice()
                    .sort((a, b) => a.sortOrder - b.sortOrder)
                    .map((event) => (
                        <EventCard
                            addEvent={addEvent}
                            event={event}
                            key={event.id}
                            currencyISO={currencyISO}
                            selectedEventOccations={selectedEventOccations}
                            timeSettingAmPm={timeSettingAmPm}
                        />
                    ))}
            </Box>
            <AppBar
                position="sticky"
                color="transparent"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    textAlign: 'center',
                    py: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    px: 2,
                    zIndex: 9999
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={eventQuantity <= 0}
                    disableElevation
                    sx={{ borderRadius: 20, height: 40, px: 4 }}
                    onClick={() => {
                        goToCart();
                    }}>
                    {t('translation.addonsSelection.goToCartButton')}
                </Button>
                <Footer/>
            </AppBar>
        </Box>
    );
}
