import { TextField } from '@mui/material';
import { FreeTextQuestionItem } from '../interface';
import { getCurrentLanguageFromLocalStorage, translate } from '../../../utils/languageTranslation.util';

interface Props {
    question: FreeTextQuestionItem;
    index: number;
    updateQuestion: (index: number, answer: string) => void;
}

export default function FreeText({ question, index, updateQuestion }: Props) {
    return (
        <>
            <span>
                {translate(question.question, getCurrentLanguageFromLocalStorage())}
                {question.mandatory && <> *</>}
            </span>
            <TextField
                sx={{ backgroundColor: 'white', borderRadius: '16px' }}
                multiline
                minRows={2}
                maxRows={6}
                inputProps={{ maxLength: 1000 }}
                fullWidth
                value={question.answer ?? ''}
                onChange={(e) => {
                    updateQuestion(index, e.target.value);
                }}></TextField>
        </>
    );
}
