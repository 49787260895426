import React from 'react';
import { AccountInformation } from './interface';
import { useTranslation } from 'react-i18next';
import { Site } from '../product/interfaces';
import { Box } from '@mui/material';

interface Props {
    accountInformation: AccountInformation;
    siteInformation: Site | null;
}

export default function CancelledMessage({ accountInformation, siteInformation }: Props) {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column'
            }}>
            <h1>{t('translation.unlock.cancelledTitle')}</h1>
            <Box
                dangerouslySetInnerHTML={{
                    __html: t('translation.unlock.questionMessage', {
                        0: siteInformation?.email ?? '',
                        1: siteInformation?.phone ?? ''
                    })
                }}
                sx={{
                    'a[href^="mailto:"]': {
                        color: '#004E31'
                    },
                    'a[href^="tel:"]': {
                        color: '#004E31'
                    },
                    marginBottom: '1rem'
                }}></Box>
        </div>
    );
}
