import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const CountdownTimer = ({ targetDate, timezone }) => {
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(moment.tz(targetDate, timezone).diff(moment.tz(new Date(), timezone)));

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeLeft(moment.tz(targetDate, timezone).diff(moment.tz(new Date(), timezone)));
        }, 1000);
        return () => clearInterval(timerId);
    }, [targetDate, timezone]);

    if (timeLeft <= 0) {
        return <h3>{t('translation.unlock.alreadyFinished')}</h3>;
    }

    const duration = moment.duration(timeLeft);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let formattedTimeLeft = '';

    if (days > 0) {
        const daysTranslation = t('translation.unlock.days');
        formattedTimeLeft += `${days} ${daysTranslation} `;
    }
    if (hours > 0) {
        const hoursTranslation = t('translation.unlock.hours');
        formattedTimeLeft += `${String(hours).padStart(2, '0')} ${hoursTranslation} `;
    }
    if (minutes > 0) {
        const minutesTranslation = t('translation.unlock.minutes');
        formattedTimeLeft += `${String(minutes).padStart(2, '0')} ${minutesTranslation} `;
    }

    const secondsTranslation = t('translation.unlock.seconds');
    formattedTimeLeft += `${String(seconds).padStart(2, '0')} ${secondsTranslation}`;

    return (
        <>
            <h3 style={{ marginBottom: 0, marginTop: '8px' }}>{t('translation.unlock.bookingEndsIn')}</h3>
            <h3 style={{ margin: 0, color: '#004e31' }}>{formattedTimeLeft}</h3>
        </>
    );
};

export default CountdownTimer;
