export default class LocalStorageUtil {
    static setItem(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static getItem(key: string): any | null {
        const data = localStorage.getItem(key);
        if (data !== null) {
            return this.isJsonString(data) ? JSON.parse(data) : data;
        }
        return null;
    }

    // Helper function to check if a string is valid JSON
    private static isJsonString(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
