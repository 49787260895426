import { Box, Skeleton, Typography } from '@mui/material';

export default function SkeletonProductContainer() {
    return (
        <Box sx={{ display: 'flex', gap: '16px', mb: 2 }}>
            <Skeleton
                variant="rectangular"
                sx={{ alignSelf: 'center', width: '120px', height: '120px', marginRight: '16px', textAlign: 'center', cursor: 'pointer' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'Column' }}>
                <Typography variant="h4" width={'80%'}>
                    <Skeleton />
                </Typography>
                <Skeleton width={'100%'} />
                <Typography variant="h3" width={'30%'}>
                    <Skeleton />
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, mt: 1, gap: '16px' }}>
                    <Skeleton variant="rectangular" width={65} height={40} sx={{ borderRadius: '20px' }} />
                    <Skeleton variant="rectangular" width={106} height={40} sx={{ borderRadius: '20px' }} />
                </Box>
                <Skeleton variant="rectangular" width={185} height={40} sx={{ borderRadius: '20px' }} />
            </Box>
        </Box>
    );
}
