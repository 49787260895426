import { RatingQuestionItem } from '../interface';
import { getCurrentLanguageFromLocalStorage, translate } from '../../../utils/languageTranslation.util';
import Rating from '@mui/material/Rating';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

interface Props {
    question: RatingQuestionItem;
    index: number;
    updateQuestion: (index: number, answer: number) => void;
}

export default function RatingQuestion({ question, index, updateQuestion }: Props) {
    return (
        <>
            <div>
                {translate(question.question, getCurrentLanguageFromLocalStorage())}
                {question.mandatory && <> *</>}
            </div>
            <Rating
                emptyIcon={
                    <StarOutlineIcon
                        sx={{
                            color: 'black'
                        }}
                        fontSize="inherit"
                    />
                }
                name="size-large"
                value={question.answer ?? 0}
                size="large"
                max={parseInt(question.scale)}
                onChange={(event, newValue) => {
                    if (newValue) {
                        updateQuestion(index, newValue ?? 0);
                    }
                }}
            />
        </>
    );
}
