import { Box, Skeleton } from '@mui/material';

export default function SkeletonAppBarTop() {
    return (
        <Box width={'85%'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton variant="text" width={236} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="circular" width={30} height={30} />
        </Box>
    );
}
