import { Button, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ImageItem } from '../interface';
import { getCurrentLanguageFromLocalStorage, translate } from '../../../utils/languageTranslation.util';
import { useTranslation } from 'react-i18next';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import axiosInstance from '../../../utils/axios';
import imageCompression from 'browser-image-compression';

interface Props {
    question: ImageItem;
    bookingId: string;
    updateQuestion: (answer: string) => void;
}

const ImageUpload = ({ question, bookingId, updateQuestion }: Props) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isImageLoading, setIsImageLoading] = useState(false);

    const uploadImageToSignedUrl = async (signedUrl: string, file: File) => {
        try {
            const response = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error when uploading:', error);
            setSelectedFile(null);
            setBase64Image(null);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            try {
                setIsImageLoading(true);
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                };

                const compressedFile = await imageCompression(file, options);
                setSelectedFile(compressedFile);

                const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);
                setBase64Image(compressedBase64);

                const uploadFilePath = await uploadImage(question.imageId, file.name, compressedFile, compressedBase64);
                updateQuestion(uploadFilePath);
            } catch (error) {
                console.error('Error during image compression or upload:', error);
            } finally {
                setIsImageLoading(false);
            }
        }
    };

    const uploadImage = async (imageId: number, filename: string, compressedFile: File, compressedBase64: string): Promise<string> => {
        try {
            const response = await axiosInstance.get(`sites/v1/questionnaire/upload/${bookingId}/after/${imageId}/${filename}`);
            if (response.status === 200) {
                const matches = compressedBase64?.match(/^data:(.*);base64,(.*)$/);
                if (!matches || matches.length !== 3) {
                    throw new Error('Invalid base64 image format');
                }

                const signedUrl = response.data.url;
                await uploadImageToSignedUrl(signedUrl, compressedFile);

                return response.data.filePath as string;
            } else {
                throw new Error('Something went wrong when uploading image');
            }
        } catch (error) {
            setBase64Image(null);
            setSelectedFile(null);
            console.error('Error when uploading:', error);
            return '';
        }
    };

    return (
        <div className="img-upload-container">
            <div className="img-upload-container__left-column">
                <div style={{ marginBottom: '4px' }}>
                    {translate(question.question, getCurrentLanguageFromLocalStorage())}
                    {question.mandatory && <> *</>}
                </div>
                <input ref={inputRef} type="file" accept="image/*" capture="environment" onChange={handleFileChange} style={{ display: 'none' }} />
                <Button
                    variant="contained"
                    endIcon={<CameraAltRoundedIcon />}
                    className="img-upload-container__button"
                    onClick={() => inputRef.current?.click()}
                    fullWidth>
                    {base64Image ? t('translation.beforeUnlock.changePicture') : t('translation.beforeUnlock.takeAPicture')}
                </Button>
            </div>
            {base64Image && selectedFile ? (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={base64Image} alt="Captured" style={{ maxWidth: '100%', display: 'block' }} />
                    {isImageLoading && (
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    inset: 0,
                                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                                    zIndex: 1,
                                    borderRadius: '15px'
                                }}
                            />
                            <CircularProgress
                                size={30}
                                sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-16px', marginLeft: '-16px', zIndex: 2 }}
                            />
                        </>
                    )}
                </div>
            ) : (
                <img src="/placeholder.png" alt="Upload" className="image-preview" />
            )}
        </div>
    );
};
export default ImageUpload;
