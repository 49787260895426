import { Skeleton } from '@mui/material';
import { CSSProperties, MouseEventHandler, useState } from 'react';

export enum skeletonShapeEnum {
    RECTANGULAR = 'rectangular',
    CIRCULAR = 'circular'
}

interface Props {
    skeletonShape: skeletonShapeEnum;
    skeletonHeight: number | string;
    skeletonWidth: number | string;
    imgAlt: string;
    imgSrc: string;
    imgStyle: CSSProperties | undefined;
    onClickFunction?: MouseEventHandler<HTMLImageElement>;
}

const hideImage: CSSProperties = { display: 'none' };

export const ImageWithSkeleton = ({ skeletonShape, skeletonHeight, skeletonWidth, imgAlt, imgSrc, imgStyle, onClickFunction }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const onLoadImage = () => setIsLoading(false);

    return (
        <>
            {isLoading && <Skeleton variant={skeletonShape} width={skeletonWidth} height={skeletonHeight} />}
            <img alt={imgAlt} src={imgSrc} style={isLoading ? hideImage : imgStyle} onClick={onClickFunction} onLoad={onLoadImage} />
        </>
    );
};
