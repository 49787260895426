import moment from 'moment';
import { Moment } from 'moment';

export default function convertToBookingFormat(moment: Moment) {
    return moment.format('YYYY-MM-DDTHH:mm:ss');
}

export const formatTime = (time: string, timeAmPm: boolean) => {
    return timeAmPm ? moment(time, 'HH:mm').format('hh:mmA') : moment(time, 'HH:mm').format('HH:mm');
};

export const formatTimeToDateObject = (momentObj: Moment) => {
    return momentObj.startOf('day').toDate();
    // return new Date(momentObj.format('YYYY-MM-DD HH:mm:ss'));
};
