import { Box, Stack, Grid, FormControl, Select, MenuItem, IconButton, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Product, ProductTypes } from './../product/interfaces';
import ProductHelpers from './../product/ProductHelpers';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import InfoTooltip from './InfoTooltip';
import { ImageWithSkeleton, skeletonShapeEnum } from '../elements/ImageWithSkeleton';

interface Props {
    selectedProducts: Product[];
    setSelectedProducts?: (products: Product[]) => void;
    disableEdit?: boolean;
    currencyISO: string;
    setQRFlow?: (QRFlow: boolean) => void;
    QRFlow?: boolean;
    backToProducts?: () => void;
}

const ProductSummary = ({ selectedProducts, setSelectedProducts, disableEdit = false, currencyISO, setQRFlow, QRFlow, backToProducts }: Props) => {
    const updateQuantity = (quantity: number, product: Product) => {
        const selectedProductsReturn = [...selectedProducts];
        const objIndex = selectedProductsReturn.findIndex((selectedProduct) => selectedProduct.id === product.id);
        if (objIndex >= 0) {
            selectedProductsReturn[objIndex].quantity = quantity;
        }

        if (setSelectedProducts) {
            setSelectedProducts(selectedProductsReturn);
        }
    };

    const removeProduct = (product: Product) => {
        const selectedProductsReturn = [...selectedProducts];
        const objIndex = selectedProductsReturn.findIndex((selectedProduct) => selectedProduct.id === product.id);

        if (objIndex >= 0) {
            selectedProductsReturn.splice(objIndex, 1);
        }

        if (setSelectedProducts) {
            setSelectedProducts(selectedProductsReturn);
        }

        if (QRFlow && setQRFlow && setSelectedProducts && backToProducts && product.type === 0) {
            backToProducts();
        }

        // Track the remove_from_cart event
        if (typeof window.gtag === 'function') {
            const itemsForGTM = [
                {
                    item_id: product.id,
                    item_name: typeof product.name === 'string' ? product.name : product.name.en,
                    price: product.quantity * product.cost,
                    quantity: product.quantity
                }
            ];
            window.gtag('event', 'remove_from_cart', {
                items: itemsForGTM
            });
        }
    };

    return (
        <Stack spacing={1} sx={{ px: 0, py: 1 }}>
            <Grid container spacing={0}>
                {selectedProducts
                    .filter((item) => item.type !== ProductTypes.INSURANCE)
                    .map((product, productId) => (
                        <Grid
                            container
                            item
                            spacing={0}
                            sx={{
                                alignItems: 'center',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                                pb: 1,
                                pt: 1
                            }}
                            key={productId}>
                            {!disableEdit && !QRFlow && (
                                <Grid item xs={1}>
                                    <IconButton onClick={() => removeProduct(product)} size="small">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item xs={2} sx={{ textAlign: 'center' }}>
                                <Box
                                    sx={{
                                        alignSelf: 'center',
                                        height: '60px',
                                        width: '60px',
                                        textAlign: 'center',
                                        display: 'flex'
                                    }}>
                                    <ImageWithSkeleton
                                        skeletonShape={skeletonShapeEnum.RECTANGULAR}
                                        imgSrc={product.productImageURL}
                                        imgAlt="product"
                                        skeletonHeight={60}
                                        skeletonWidth={60}
                                        imgStyle={{ maxHeight: 60, maxWidth: 60, margin: 'auto' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={disableEdit ? 7 : 5} sx={{ pl: 3, pr: 1 }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {translate(product.name, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                    {translate(product.description, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                            </Grid>
                            <Grid item xs={disableEdit ? 1 : 2}>
                                {disableEdit || QRFlow ? (
                                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                        {product.quantity}
                                    </Typography>
                                ) : (
                                    <FormControl sx={{ minWidth: 50 }}>
                                        <Select
                                            value={product.quantity}
                                            onChange={(event) => {
                                                updateQuantity(event.target.value as number, product);
                                            }}
                                            sx={{
                                                borderRadius: 20,
                                                maxHeight: 40,
                                                border: 'solid 1px #004E31'
                                            }}
                                            MenuProps={{ sx: { zIndex: 2147483647 } }}
                                            size="small">
                                            {[...Array(product.availability).keys()].map((key) => (
                                                <MenuItem key={key + 1} value={key + 1}>
                                                    {key + 1}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">
                                    {product.cost * product.quantity}
                                    {currencyISO}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                {selectedProducts
                    .filter((item) => item.type === ProductTypes.INSURANCE)
                    .map((insurance) => (
                        <Grid
                            container
                            item
                            spacing={0}
                            sx={{
                                alignItems: 'center',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                                pb: 1,
                                pt: 1
                            }}
                            key={insurance.id}>
                            {!disableEdit && (
                                <Grid item xs={1}>
                                    {insurance.forcingFee === false && (
                                        <IconButton onClick={() => removeProduct(insurance)} size="small">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    textAlign: 'center',
                                    height: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <InfoTooltip insurance={insurance} />
                            </Grid>
                            <Grid item xs={disableEdit ? 8 : 7}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {translate(insurance.name, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">
                                    {ProductHelpers.getInsurancePrice(insurance, selectedProducts)}
                                    {currencyISO}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </Stack>
    );
};

export default ProductSummary;
