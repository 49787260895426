import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import StarIcon from '@mui/icons-material/Star';
import logo from '../../../assets/googleReviews/google_icon.png';
import ReviewCard from './ReviewCard';
import { useTranslation } from 'react-i18next';

declare global {
    interface Window {
        initMap: () => void;
        google: typeof google;
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

interface GoogleReviewsProps {
    reviewUrl?: string;
    placeId?: string;
}

interface Review {
    profile_photo_url?: string;
    author_name?: string;
    relative_time_description?: string;
    rating?: number;
    text?: string;
}

interface PlaceDetails {
    name?: string;
    rating?: number;
    user_ratings_total?: number;
    reviews?: Review[];
}

const GoogleReviews: React.FC<GoogleReviewsProps> = ({ reviewUrl, placeId }) => {
    const [placeDetails, setPlaceDetails] = useState<PlaceDetails | null>(null);
    const { t } = useTranslation();

    const safeInitMap = () => {
        if (typeof window.initMap === "function") {
            window.initMap();
        } else {
            console.error("initMap is not defined on window");
        }
    };

    useEffect(() => {
        const loadGoogleMapsScript = (callbackName: string) => {
            if (window.google && window.google.maps && window.google.maps.places) {
                safeInitMap();
                return;
            }

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&callback=${callbackName}`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        };

        window.initMap = () => {
            if (!window.google || !window.google.maps || !window.google.maps.places) {
                console.error('Google Maps JavaScript API not loaded');
                return;
            }

            if (placeId) {
                const map = new google.maps.Map(document.createElement('div'));
                const service = new google.maps.places.PlacesService(map);

                service.getDetails(
                    {
                        placeId: placeId,
                        fields: ['name', 'rating', 'user_ratings_total', 'reviews']
                    },
                    (result, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
                            const details: PlaceDetails = {
                                name: result.name,
                                rating: result.rating,
                                user_ratings_total: result.user_ratings_total,
                                reviews: result.reviews as Review[] 
                            };
                            setPlaceDetails(details);
                        }
                    }
                );
            } else {
                console.error('placeId is undefined');
            }
        };

        loadGoogleMapsScript('initMap');

        return () => {
            const script = document.querySelector(`script[src="${process.env.REACT_APP_GOOGLE_MAP_KEY}"]`);
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [placeId]);

    return (
        <>
            {placeDetails && (
                <Paper elevation={1} sx={{ p: 2, backgroundColor: '#f6f6f6', width: '100%' }}>
                    <Box sx={{ p: 2, mb: 2, borderRadius: 5, backgroundColor: 'white' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 1 }}>
                            <img src={logo} alt="Google Reviews Logo" style={{ height: '36px', marginLeft: -8 }} />
                            <Typography variant="h4" sx={{ ml: 1, lineHeight: 'normal', color: 'black', fontWeight: '400' }}>
                                {' '}
                                {t('translation.return.rating')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 1 }}>
                            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', lineHeight: 'normal', mb: 0 }}>
                                <Typography variant="body2" sx={{ lineHeight: 'normal', margin: 0, color: '#808080' }}>
                                    {placeDetails?.user_ratings_total || 0} reviews
                                </Typography>
                            </Typography>
                            <Rating
                                name="read-only"
                                value={placeDetails?.rating}
                                precision={0.1}
                                size="medium"
                                readOnly
                                sx={{ mx: 1 }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            <Typography variant="body2" sx={{ lineHeight: 'normal', margin: 0, color: '#808080' }}>
                                {placeDetails?.user_ratings_total} reviews
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'left', mt: 1 }}>
                            {' '}
                            <Link
                                href={reviewUrl ? reviewUrl : `https://search.google.com/local/writereview?placeid=${placeId}`}
                                underline="none"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#4285F4',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#357ae8'
                                        }
                                    }}>
                                    {t('translation.return.writeReview')}
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    {placeDetails && placeDetails.reviews ? (
                        <Carousel>
                            {placeDetails?.reviews.map((review, i) => (
                                <ReviewCard key={i} review={review} />
                            ))}
                        </Carousel>
                    ) : (
                        <Box sx={{ p: 2, mb: 2, borderRadius: 5, backgroundColor: 'white' }}>
                            <Typography>{t('translation.return.noReviews')}</Typography>
                        </Box>
                    )}
                </Paper>
            )}
        </>
    );
};

export default GoogleReviews;
