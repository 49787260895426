import { Badge, IconButton } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { BookingType } from '../product/interfaces';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        // backgroundColor: '#004E31',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
        border: '2px solid #004E31'
    }),
    ...(ownerState.completed && {
        // backgroundColor: '#004E31'
        border: '2px solid #004E31'
    })
}));

export default function ColorlibStepIcon(props: any) {
    const { active, completed, className, totalQuantity, goToStep, selectedRentalType, selectedBookingType, promoQuantity, isAddon } = props;

    const icons: { [index: string]: React.ReactElement } =
        selectedBookingType === BookingType.EVENTS || !isAddon
            ? {
                  1: (
                      <IconButton onClick={() => goToStep(0)}>
                          <img alt="" src={selectedRentalType[0]?.img} width="24" height="24" />
                      </IconButton>
                  ),
                  2: (
                      <IconButton onClick={() => goToStep(2)}>
                          <Badge color="primary" badgeContent={totalQuantity}>
                              <ShoppingCartOutlinedIcon sx={{ fontSize: '24px' }} />
                          </Badge>
                      </IconButton>
                  )
              }
            : {
                  1: (
                      <IconButton onClick={() => goToStep(0)}>
                          <img alt="" src={selectedRentalType[0]?.img} width="24" height="24" />
                      </IconButton>
                  ),
                  2: (
                      <IconButton onClick={() => goToStep(1)}>
                          <img alt="" src={selectedRentalType[0]?.img2} width="24" height="24" />
                      </IconButton>
                  ),
                  3: (
                      <IconButton onClick={() => goToStep(2)}>
                          <Badge color="primary" badgeContent={totalQuantity}>
                              <ShoppingCartOutlinedIcon sx={{ fontSize: '24px' }} />
                          </Badge>
                      </IconButton>
                  )
              };

    const promoIcons: { [index: string]: React.ReactElement } = {
        1: (
            <IconButton onClick={() => goToStep(0)}>
                <CardGiftcardIcon sx={{ fontSize: '24px' }} />
            </IconButton>
        ),
        2: (
            <IconButton onClick={() => goToStep(2)}>
                <Badge color="primary" badgeContent={promoQuantity}>
                    <ShoppingCartOutlinedIcon sx={{ fontSize: '24px' }} />
                </Badge>
            </IconButton>
        )
    };

    if (selectedBookingType === BookingType.GIFTCARDS) {
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {selectedRentalType[0] && promoIcons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {selectedRentalType[0] && icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    totalQuantity: PropTypes.number,
    promoQuantity: PropTypes.number,
    goToStep: PropTypes.func,
    site: PropTypes.object
};
