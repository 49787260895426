import { AppBar, Box, Dialog, DialogContent, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import theme from '../../theme';
import axiosInstance from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { removeURLParameter } from '../../utils/UrlHelper';
import { EventV1, Product } from '../product/interfaces';
import SkeletonAppBarTop from '../skeletons/SkeletonAppBarTop';
import SkeletonStatusLoadingBottom from '../skeletons/SkeletonStatusLoadingBottom';

interface Props {
    statusId: string;
    setStatusId: (statusId: string) => void;
    showModal: boolean;
    closeModal: (onConfirmFunction?: () => void) => void;
    setOrderId: (orderId: string) => void;
    setInitialCartData: (initialCartData: InitialCartData) => void;
    setSiteId: (siteId: string) => void;
    setUnlockOrderId: (unlockOrderId: string) => void;
}

export enum StripeSessionStatusV1 {
    open = 'open',
    complete = 'complete',
    expired = 'expired'
}

interface BookingStatusObject {
    status: StripeSessionStatusV1;
    url: string;
    cartData: InitialCartData;
}

export interface InitialCartData {
    bookingId: string;
    createdAt: string;
    endDate: string;
    events: EventV1[];
    extraInfo: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    products: Product[];
    promoCode: string;
    startDate: string;
    totalCost: number;
    totalVAT: string;
    updatedAt: string;
    siteId: string;
}

function getUnlockOrderValue(queryString) {
    // Assuming the queryString is something like "?unlockOrder=wqSzUr4FGbGB5YFvBm2N&testId=hUIsadhuiashd"
    // If your queryString starts directly with "&", prepend a "?" to make it a valid query string
    if (queryString[0] === '&') {
        queryString = '?' + queryString.slice(1);
    }

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Get the value of the 'unlockOrder' parameter
    return params.get('unlockOrder');
}

export default function ShowStatusLoadingFrame({
    statusId,
    setStatusId,
    showModal,
    closeModal,
    setOrderId,
    setInitialCartData,
    setSiteId,
    setUnlockOrderId
}: Props) {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showExpiredError, setShowExpiredError] = useState(false);
    const { t, i18n } = useTranslation();

    const getStatus = async (statusId: string) => {
        const response = await axiosInstance.get<BookingStatusObject>(`/booking/v1/status/${statusId}`);
        if (response.status === 200 && response.data.status === StripeSessionStatusV1.complete) {
            setOrderId(statusId);
            const unlockOrder = getUnlockOrderValue(response.data.url);
            if (unlockOrder) {
                setUnlockOrderId(unlockOrder);
            }
        } else if (
            response.status === 200 &&
            (response.data.status === StripeSessionStatusV1.open || response.data.status === StripeSessionStatusV1.expired)
        ) {
            setInitialCartData(response.data.cartData);
            setSiteId(response.data.cartData.siteId);
            setStatusId('');
        }
    };

    // forcing language change on mount from local storage
    useEffect(() => {
        const setLanguage = () => {
            // Get the stored language or default to 'en' if null
            const storedLanguage = localStorage.getItem('user-language-selected') || 'en';
            const allowedLanguages = ['de', 'fr', 'sv', 'en', 'no', 'da', 'es'];
            const languageToSet = allowedLanguages.includes(storedLanguage) ? storedLanguage : 'en';
            if (i18n.language !== languageToSet) {
                i18n.changeLanguage(languageToSet);
            }
        };

        setLanguage(); // Set the language on mount

        // Listener for changes in local storage to update language
        const handleStorageEvent = (event) => {
            if (event.key === 'user-language-selected') {
                setLanguage();
            }
        };

        window.addEventListener('storage', handleStorageEvent);

        // Cleanup listener when the component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [i18n]);

    useEffect(() => {
        // Remove query-parameters coming from acceptUrl
        let newUrl = removeURLParameter(window.location.toString(), 'status');
        window.history.pushState({}, document.title, newUrl);
    }, []);

    useEffect(() => {
        let interval;
        if (statusId && showExpiredError === false) {
            // Setup interval to check status
            interval = setInterval(() => {
                if (showExpiredError) {
                    clearInterval(interval);
                } else {
                    getStatus(statusId);
                }
            }, 3000); // Interval set at 3 seconds
        }
        // Cleanup function to clear interval if component unmounts
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusId, setShowExpiredError]);

    return (
        <Dialog
            open={showModal}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullScreen={fullScreen}
            fullWidth
            sx={{ zIndex: 999, fontSize: '16px' }}
            PaperProps={{
                style: {
                    height: '100%'
                }
            }}>
            <AppBar position="sticky" color="transparent" sx={{ px: 2, pt: '8px', backgroundColor: '#ffffff' }}>
                <Box sx={{ display: 'flex', mb: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                    <SkeletonAppBarTop />
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            closeModal();
                        }}
                        sx={{
                            zIndex: 9999
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </AppBar>
            <DialogContent style={{ padding: '16px', paddingBottom: 0, position: 'relative', minHeight: '360px' }}>
                {showExpiredError ? (
                    <h3>{t('translation.statusFrame.paymentExpired')}</h3>
                ) : (
                    <>
                        <SkeletonStatusLoadingBottom />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
