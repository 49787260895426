import React, { useState } from 'react';
import { ClickAwayListener, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { translate, getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import { Product } from './interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    customTooltip: {
        overflowY: 'auto'
    }
}));

interface Props {
    insurance: Product;
}

const InfoTooltip = ({ insurance }: Props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const classes = useStyles();

    const handleIconClick = () => {
        if (!isDesktop) {
            setTooltipOpen((prev) => !prev);
        }
    };

    const handleClose = () => {
        if (!isDesktop) {
            setTooltipOpen(false);
        }
    };

    return (
        <>
            {!isDesktop ? (
                <ClickAwayListener onClickAway={handleClose}>
                    <Tooltip
                        classes={{ tooltip: classes.customTooltip }}
                        title={translate(insurance.description, getCurrentLanguageFromLocalStorage())}
                        open={tooltipOpen}
                        disableHoverListener
                        disableFocusListener
                        disableTouchListener
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}>
                        <InfoOutlinedIcon sx={{ mr: 1, fontSize: '1.85em' }} onClick={handleIconClick} />
                    </Tooltip>
                </ClickAwayListener>
            ) : (
                <Tooltip className={classes.customTooltip} title={translate(insurance.description, getCurrentLanguageFromLocalStorage())}>
                    <InfoOutlinedIcon sx={{ mr: 1, fontSize: '1.85em' }} />
                </Tooltip>
            )}
        </>
    );
};

export default InfoTooltip;
