import i18n from '../i18n';
import LocalStorageUtil from './localStorage.util';

export default class AppUtil {
    static changeLanguage(language) {
        LocalStorageUtil.setItem('user-language-selected', language);
        i18n.changeLanguage(language);
        const event = new CustomEvent('customStorageChange', {
            detail: {
                key: 'user-language-selected',
                newValue: language
            }
        });

        window.dispatchEvent(event);
    }
}
